import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApplicationGlobalsService } from 'app-services/applicationGlobals.service';
import { getHttpRequestOptions } from 'app-scripts/utilities/general';
import { HttpRequest } from 'app-models/common/httpRequest.model';
import { _throw } from 'rxjs/observable/throw';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs/Observable';
import { forkJoin } from 'rxjs/observable/forkJoin';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { merge } from 'lodash';

@Injectable()
export class HttpRequestService {

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private applicationGlobalsService: ApplicationGlobalsService
    ) {
        getHttpRequestOptions();
    }

    public post = (url: string, body?: any, watchResponse?: boolean): Observable<any> => {
        const options: { observe: 'response' } = watchResponse ? { observe: 'response' } : undefined;
        return this.httpClient.post<any>(url, body, merge(getHttpRequestOptions(), options))
            .map((res: any) => {
                if (res === null) {
                    return undefined;
                }
                return this.extractData(res);
            })
            .catch((error: any) => {
                return this.handleErrorObservable(error);
            });
    }

    public forkJoin = (requests: HttpRequest[]): Observable<any> => {
        const r = requests.map(request => {
            switch (request.type) {
                case 'post':
                    if (request.body === undefined) {
                        request.body = {};
                    }
                    return this.httpClient.post(request.url,
                        JSON.stringify(request.body),
                        getHttpRequestOptions());
                case 'get':
                    return this.httpClient.get(request.url, getHttpRequestOptions());
            }
        });
        return forkJoin(r)
            .map(results => {
                const returnValue = [];
                if (results === null) {
                    return undefined;
                }
                return results.map((res: any) => {
                    return this.extractData(res);
                });
            })
            .catch((error: any) => {
                return this.handleErrorObservable(error);
            });
    }

    public get = (url: string): Observable<any> => {
        return this.httpClient.get(url, getHttpRequestOptions())
            .map((res: any) => {
                if (res === null) {
                    return undefined;
                }
                return this.extractData(res);
            })
            .catch((error: any) => {
                return this.handleErrorObservable(error);
            });
    }

    public delete = (url: string) => {
      return this.httpClient.delete(url, getHttpRequestOptions());
    }

    private extractData = (res: HttpResponse<any>) => {
        return res;
    }

    private handleErrorObservable = (e: Response | any) => {
        if (e.status === 401) {
            const currentUrl = window.location.href;
            const authUrl = this.applicationGlobalsService.globalConfigurations.authUrl;
            if (!window.location.href.contains('login')) {
              window.location.href = authUrl + currentUrl;
            }
        }
        if (e.status === 403) {
          if (!window.location.href.contains('login')) {
            this.router.navigate(['/error']);
          }
        }
        return _throw(e);
    }
}
